<template>
    <h1>{{ title }}</h1>
</template>

<script>
export default {
	name: 'TitleText',
	props: {
		title: {
			type: String,
			default: null
		}
	}
}
</script>
